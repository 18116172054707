import { CmsImageType } from 'utils/cms/types';

export type USP = {
  usp_title: string;
  usp_description: string;
  usp_image: CmsImageType;
  usp_background: CmsImageType;
};

type Props = {
  usp: USP;
};

const Usp = ({ usp }: Props) => (
  <div className="flex flex-col items-center gap-3">
    <div className="relative">
      <img alt={usp.usp_image.alt} className="absolute bottom-0 left-0 right-0 rounded-lg" src={usp.usp_image.url} />
      <img alt={usp.usp_background.alt} className="w-full aspect-video shadow-overlap" src={usp.usp_background.url} />
    </div>
    <div className="flex flex-col items-center gap-1">
      <h4 className="title-4 text-white text-center text-shadow-lg shadow-black font-tiller">{usp.usp_title}</h4>
      <p className="text-white text-center text-shadow-lg shadow-black font-tiller font-bold md:text-lg">
        {usp.usp_description}
      </p>
    </div>
  </div>
);

export default Usp;
