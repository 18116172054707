import clx from 'classnames';
import LargeGold from '@/svg/ornaments/large-gold.svg';
import LargeSimple from '@/svg/ornaments/large-simple.svg';
import MediumGold from '@/svg/ornaments/medium-gold.svg';
import MediumSimple from '@/svg/ornaments/medium-simple.svg';
import SmallGold from '@/svg/ornaments/small-gold.svg';
import SmallSimple from '@/svg/ornaments/small-simple.svg';

export enum ORNAMENT_VARIANTS {
  'LARGE_GOLD' = 'LARGE_GOLD',
  'LARGE_SIMPLE' = 'LARGE_SIMPLE',
  'MEDIUM_GOLD' = 'MEDIUM_GOLD',
  'MEDIUM_SIMPLE' = 'MEDIUM_SIMPLE',
  'SMALL_GOLD' = 'SMALL_GOLD',
  'SMALL_SIMPLE' = 'SMALL_SIMPLE',
}

const VARIANT_TO_COMPONENT = {
  [ORNAMENT_VARIANTS.LARGE_GOLD]: <LargeGold />,
  [ORNAMENT_VARIANTS.LARGE_SIMPLE]: <LargeSimple />,
  [ORNAMENT_VARIANTS.MEDIUM_GOLD]: <MediumGold />,
  [ORNAMENT_VARIANTS.MEDIUM_SIMPLE]: <MediumSimple />,
  [ORNAMENT_VARIANTS.SMALL_GOLD]: <SmallGold />,
  [ORNAMENT_VARIANTS.SMALL_SIMPLE]: <SmallSimple />,
};

type OrnamentProps = {
  variant: ORNAMENT_VARIANTS;
  className?: string;
  direction?: 'left' | 'right';
};

const Ornament = ({ variant, className, direction = 'left' }: OrnamentProps) => {
  const Component = VARIANT_TO_COMPONENT[variant];

  return <div className={clx(className, direction === 'right' && '[transform:rotateY(180deg)]')}>{Component}</div>;
};

export default Ornament;
