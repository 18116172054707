/* eslint-disable camelcase */
import Button from '@/atoms/Button';
import Ornament, { ORNAMENT_VARIANTS } from '@/atoms/Ornemental';
import Carrousel from '@/molecules/Carrousel/components/elements';
import TitleBox from '@/molecules/titles/TitleBox';
import ArticleTile from '@/organisms/ArticleTile';
import Usp, { USP } from 'components/widgets/edito/CoverHomepage/Usp';
import { CMSCallToActionType, CMSNewsType, CmsImageType } from 'utils/cms/types';

export type CoverHomepageWidgetProps = {
  logo_image: CmsImageType;
  primary_cta: CMSCallToActionType;
  secondary_cta: CMSCallToActionType;
  title: string;
  background_image: CmsImageType;
  background_video: CmsImageType;
  video_placeholder: CmsImageType;
  usp: USP[] | false;
  articles: CMSNewsType[];
  cta: CMSCallToActionType;
  article_title: string;
  url?: string;
};

const CoverHomepageWidget = ({
  logo_image: logo,
  primary_cta: primaryCta,
  secondary_cta: secondaryCta,
  title,
  background_image: backgroundImage,
  background_video: backgroundVideo,
  video_placeholder: videoPlaceholder,
  usp,
  articles,
  article_title,
  cta,
  url,
}: CoverHomepageWidgetProps) => (
  <div className="w-full">
    <div className="relative h-[600px] md:h-[786px] w-full z-5 clip-cover pb-4 md:pb-6">
      <div className="absolute top-0 left-0 right-0 bottom-0 z-0">
        {backgroundVideo.type === 'image' && (
          <img alt={backgroundVideo.alt} className="object-cover w-full h-full" src={backgroundVideo.url} />
        )}
        {backgroundVideo.type === 'video' && (
          <video
            className="object-cover w-full h-full"
            controls={false}
            poster={videoPlaceholder.url}
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={backgroundVideo.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-illustrative-contrast/70 z-1" />
      {/* Bottom left side */}
      <Ornament
        className="absolute hidden md:block md:bottom-7 left-3 origin-center [transform:rotateZ(180deg)_rotateY(180deg)] z-[2]"
        direction="left"
        variant={ORNAMENT_VARIANTS.MEDIUM_GOLD}
      />
      <Ornament
        className="absolute bottom-4 md:hidden left-2 origin-center [transform:rotateZ(180deg)_rotateY(180deg)] z-[2]"
        direction="left"
        variant={ORNAMENT_VARIANTS.SMALL_GOLD}
      />
      {/* Bottom right side */}
      <Ornament
        className="absolute hidden md:block md:bottom-7 right-3 origin-center [transform:rotateZ(180deg)] z-[2]"
        direction="left"
        variant={ORNAMENT_VARIANTS.MEDIUM_GOLD}
      />
      <Ornament
        className="absolute bottom-4 md:hidden right-2 origin-center [transform:rotateZ(180deg)] z-[2]"
        direction="left"
        variant={ORNAMENT_VARIANTS.SMALL_GOLD}
      />
      <div className="h-full w-full z-[2] relative flex flex-col items-center">
        <div className="flex flex-col w-full md:w-auto flex-1 gap-6 px-6 justify-center">
          <div className="flex flex-col items-center gap-2">
            <h4 className="title-4 text-white text-center text-shadow-lg shadow-black">{title}</h4>
            <img alt={logo.alt} className="h-[100px] md:h-[180px]" src={logo.url} />
          </div>
          <div className="flex flex-col w-full items-center md:flex-row gap-2 md:gap-3 md:px-12">
            {!!secondaryCta.link && (
              <Button
                className="w-full text-white"
                href={secondaryCta.link.url}
                icon={secondaryCta.icon}
                size={secondaryCta.size}
                variant={secondaryCta.variant}
              >
                {secondaryCta.link?.title}
              </Button>
            )}
            {!!primaryCta.link && (
              <Button
                className="w-full"
                href={primaryCta.link.url}
                icon={primaryCta.icon}
                size={primaryCta.size}
                target="_blank"
                variant={primaryCta.variant}
              >
                {primaryCta.link?.title}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
    {usp && (
      <div className="relative w-full z-0 clip-cover pb-2 md:pb-4">
        <div className="absolute top-0 left-0 right-0 bottom-0 z-0">
          <img alt={backgroundImage.alt} className="object-cover w-full h-full" src={backgroundImage.url} />
        </div>
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-illustrative-contrast/20 z-1" />
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-illustrative-contrast/0 to-illustrative-contrast z-[2]" />

        <div className="h-full w-full z-[3] relative py-8 md:py-32 px-0 md:px-[120px]">
          <div className="w-full">
            <Carrousel.Root
              options={{
                perPage: usp.length,
                arrows: false,
                drag: false,
                gap: '24px',
                autoWidth: false,
                breakpoints: {
                  1024: {
                    drag: true,
                    perPage: 1,
                    gap: '16px',
                    padding: '16px',
                    autoWidth: true,
                  },
                },
              }}
            >
              <Carrousel.Body className="!mb-0">
                <Carrousel.Content>
                  {usp.map((uspItem) => (
                    <Carrousel.Slide key={uspItem.usp_title} className="w-[237px] md:w-3/12">
                      <Usp usp={uspItem} />
                    </Carrousel.Slide>
                  ))}
                </Carrousel.Content>
              </Carrousel.Body>
              <Carrousel.Pagination className="mt-8 md:hidden" />
            </Carrousel.Root>
          </div>
        </div>
      </div>
    )}
    {articles && url && !!articles.length && (
      <div className="texturedBg mt-9">
        <div className="relative z-3 max-w-7xl m-auto ">
          <TitleBox buttonProps={{ href: cta?.link?.url, children: cta?.link?.title }}>{article_title}</TitleBox>
          <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 px-4 md:px-0">
            {articles.map((article) => (
              <ArticleTile key={article.id} article={article} className="shadow-overlap" slug={url} />
            ))}
          </div>
        </div>
      </div>
    )}
  </div>
);

export default CoverHomepageWidget;
