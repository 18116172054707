import clx from 'classnames';
import { parseISO } from 'date-fns';
import { useTranslation } from 'next-i18next';

import Tile from '@/molecules/Tile';
import useDateFnsWithLocale from 'hooks/i18n/useDateFnsWithLocale';
import { SIZE, VARIANT_COLOR_ENUM } from 'types/utils';
import { CMSNewsType } from 'utils/cms/types';

type Props = { article: CMSNewsType; slug: string; className?: string };

const ArticleTile = ({ article, slug, className }: Props) => {
  const { t } = useTranslation();
  const { formatDistanceToNow } = useDateFnsWithLocale();

  const {
    title: { rendered: title },
    date,
    slug: articleSlug,
    acf: { tumbnail, resume, read_time: readTime },
    categories,
  } = article;

  return (
    <Tile
      chips={[
        ...(categories.length > 0
          ? [
              {
                children: categories[0].name,
                color: VARIANT_COLOR_ENUM.PRIMARY,
                size: SIZE.SM,
              },
            ]
          : []),
      ]}
      className={clx('h-full', className)}
      href={`${slug}/${articleSlug}`}
      image={tumbnail && tumbnail.url}
      subTitle={
        <span className="flex gap-3">
          <span>{t('ago', { time: formatDistanceToNow(parseISO(date)) })}</span>
          <span className="flex gap-1 items-baseline">
            <i className="fa-regular fa-clock-eleven-thirty" />
            {readTime}
          </span>
        </span>
      }
      title={<span className="line-clamp-2 leading-7">{title}</span>}
    >
      <p className="text-neutral-800 md-regular line-clamp-2 leading-6 text-ellipsis">{resume}</p>
    </Tile>
  );
};

export default ArticleTile;
